.dot1,
.dot2,
.dot3 {
  animation-name: dot-fade;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.dot1 {
  animation-delay: 0ms;
}

.dot2 {
  animation-delay: 150ms;
}

.dot3 {
  animation-delay: 300ms;
}

@keyframes dot-fade {
  from {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
